/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "./app/modules/shared/styles/variables";
@import "./app/modules/shared/styles/form";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

@import "./app/modules/shared/styles/mixins/spinner.mixin.scss";

@import "~@circlon/angular-tree-component/css/angular-tree-component.css";

@include mat.core($custom-typography);
@include mat.all-component-themes($custom-theme);

@mixin radr-override-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: $custom-warning;

  .mat-flat-button,
  .mat-raised-button {
    border-radius: 2px;
  }

  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary {
    background-color: $primary-light;
  }

  .mat-toolbar.mat-warn,
  .mat-flat-button.mat-warn,
  .mat-raised-button.mat-warn {
    background-color: mat.get-color-from-palette($warn);
  }

  .mat-snack-bar-container {
    animation: slideInFromTop 0.325s ease-in;
    z-index: $z-snack-bar-container; // Doesn't work right now, snackbar is still behind the loading spinner
    max-width: none !important;

    &.mat-snack-bar-container--primary {
      background-color: $primary-light;
    }
    &.mat-snack-bar-container--success {
      background-color: $success;
    }
    &.mat-snack-bar-container--error {
      background-color: $error;
    }

    .mat-simple-snackbar-action {
      color: mat.get-color-from-palette($primary, 50);
    }
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px);
    overflow: visible;
    font-size: 14px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-top: 0.8125em;
  }

  .mat-form-field-label-wrapper {
    top: -0.84375em;
  }
  .mat-form-field {
    line-height: 20px;
  }
}

@include radr-override-theme($custom-theme);

.mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-actions {
  margin-bottom: 0 !important;
}

div.radr-container {
  background: $accent-bg;
  padding: 32px;
}

div.cdk-overlay-container {
  mat-tooltip-component {
    div.mat-tooltip.radr-text-overflow-tooltip {
      overflow: visible;
      text-overflow: unset;
      word-wrap: break-word;
      max-width: 250px;
      margin: 0;
      line-height: 14px;
    }
  }

  button.mat-menu-item {
    color: $primary-light;
    .mat-icon {
      color: $primary-light;
    }

    &:disabled {
      color: $app-gray-accent;
      .mat-icon {
        color: $app-gray-accent;
      }
    }
  }
}

mat-card.radr-export-settings-card {
  padding: 24px 32px;
}

[hidden] {
  display: none !important;
}

.spinner {
  @include spinner;
}

.labeled-toggle-container {
  display: flex;
  align-items: center;

  mat-slide-toggle {
    font-size: 10px;
    padding: 3px 10px 3px 0;
  }

  .toggle-label {
    font-size: 10px;
    padding: 3px 10px 3px 0;
    line-height: 24px;
  }
}

.network-error {
  margin: 0;
}

body,
html {
  background-color: $accent-bg;
}

.count-error-warning-icon {
  color: #f5a623;
  font-size: 17px;
  width: 17px;
  height: 17px;
  margin-right: 6px;
  margin-bottom: 1px;
}

.audience-modal-container {
  min-width: 98vw !important;
  min-height: 96vh !important;
}
