@import "../_variables.scss";

@mixin spinner {
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: $primary-light;
    animation: spinner 0.8s linear infinite;
  }
}
