.radr-default-form {
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  mat-form-field {
    width: 100%;
  }

  label.mat-form-field-label {
    color: $primary-dark;
  }

  .mat-form-field-disabled {
    label.mat-form-field-label {
      color: $app-icon-gray;
    }
  }

  mat-form-field:not(:last-child) {
    margin-bottom: 18px;
  }
}

.radr-default-form-subtext {
  padding: 0 24px 20px;
  margin-top: 32px;
}

.radr-default-form-modal-sub-body {
  padding: 0 24px 20px;
  @include subBodyFont;
}

.radr-default-form__mat-form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  padding-top: 0.4375em;

  .radr-form__radio-label {
    font-size: 14px;
  }

  .mat-radio-group {
    display: inline;
    padding-top: 0.4375em;
  }

  .mat-radio-button {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}
