@use "@angular/material" as mat;
@import "@angular/material/theming";

/*
  Mixins
*/

@mixin headerFont {
  font-family: $font-family;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 20px;
  font-weight: $font-weight-accent;
  line-height: 28px;
  color: $primary-dark;
}

@mixin labelFont {
  font-family: $font-family;
  font-size: 16px;
  font-weight: $font-weight-accent;
  line-height: 22px;
  color: $black;
}

@mixin standardFont {
  font-family: $font-family;
  font-size: 16px;
  line-height: 20px;
}

@mixin vertCenteredFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin subBodyFont {
  font-family: $font-family;
  font-size: 12px;
  font-weight: $font-weight-accent;
  line-height: 14px;
  color: $black;
}

/*
  Animations
*/

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*
  Colors
*/

$primary-dark: #003057;
$primary-light: #0077bc;
$secondary: #009e8c;
$accent-bg: #f7f7f7;
$accent-border: lightgrey;
$primary-dark-font: #0e3054;
$black: #000;
$white: #fff;
$success: #14b152;
$error: #d42729;
$warning: #f5a623;
$warning-light: #f5a5238a;
$app-gray: #9b9b9b;
$app-gray-border: #d9d9d9;
$app-gray-accent: #a5a5a5;
$app-nav-underline: #4a90e2;
$app-nav-vert-divider: #4a4a4a;
$app-test-and-control-label: #4a4a4a;
$app-icon-gray: #808285;
$app-select-arrow: #404040;
$accent-text: #515150;
$accent-divider: #d8d8d8;
$table-divider: rgba(0, 0, 0, 0.12);
$off-black: #151922;
$processing: #979797;
$input-background-grey: rgba(0, 48, 87, 0.04);
$tooltip-gray: #616161;
$step-indicator: #00a18c;
$blue-highlight: #e5f1f8;

/*
  Z-Index / Elevation
*/
$z-cdk-overlay-container: 1000; // NOT USED, just a reference to default in case we wanted to override
$z-loading-spinner: 102;
$z-snack-bar-container: $z-loading-spinner + 1;

$font-family: "Open Sans";
$secondary-font: "Roboto", sans-serif;
$font-weight-accent: 400;
$gutter: 24px;
$right-side-panel-width: 353px;
$app-header-horizontal-padding: 22px;
$campaign-builder-horizontal-margin: $app-header-horizontal-padding;
$campaign-builder-vertical-margin-top: 16px;
$campaign-builder-left-list-width: 400px;
$campaign-builder-vertical-margin-bottom: 16px;
$total-count-container-top: 130px;
$execution-platforms-breakpoint: 1460px;
$qualifier-value-breakpoint: 1280px;
$file-preview-row-number-column-width: 75px;
$radr-execution-platform-control-group-top: 256px;
$campaign-builder-sticky-header-siblings-top: 266px;
$campaign-builder-sticky-header-siblings-top-limited-access: 25px;

$custom-typography: mat.define-typography-config(
  $font-family: "Open Sans"
);

$md-radr-primary: (
  50: #e0e6eb,
  100: #b3c1cd,
  200: #8098ab,
  300: #4d6e89,
  400: #264f70,
  500: #003057,
  600: #002b4f,
  700: #002446,
  800: #001e3c,
  900: #00132c,
  A100: #6595ff,
  A200: #3272ff,
  A400: #004ffe,
  A700: #0047e5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$md-radr-accent: (
  50: #e0f3f1,
  100: #b3e2dd,
  200: #80cfc6,
  300: #4dbbaf,
  400: #26ad9d,
  500: #009e8c,
  600: #009684,
  700: #008c79,
  800: #00826f,
  900: #00705c,
  A100: #9fffea,
  A200: #6cffdf,
  A400: #39ffd4,
  A700: #1fffcf,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$md-radr-warning: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$custom-warning: mat.define-palette($md-radr-warning);
$custom-primary: mat.define-palette($md-radr-primary);
$custom-accent: mat.define-palette($md-radr-accent);
$custom-theme: mat.define-light-theme($custom-primary, $custom-accent);
